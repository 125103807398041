//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future

.wrapper-editor {
  min-height: 200px;
  border: 1px;
  border-style: ridge;
  padding: 10px;
}

.card-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  .card-cover {
    position: relative;
    img {
      width: 100%;
    }
    height: 300px;
    width: 320px;
    .card-cover-action {
      position: absolute;
      bottom: 10px;
      right: 10px;
      input {
        display: none;
      }
    }
  }

  .card-pdf {
    .p-card-body,
    .p-card-content {
      height: 100%;
    }
    position: relative;
    overflow: scroll;
    img {
      width: 100%;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
    height: 530px;
    width: 100%;
    max-width: 1200px;
    .card-cover-action {
      position: absolute;
      bottom: 10px;
      right: 10px;
      input {
        display: none;
      }
    }
  }
}
